/* You can add global styles to this file, and also import other style files */
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #FFFFFF;
}
.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}
.toast-success {
  background-color: #51A351;
}
.toast-error {
  background-color: #BD362F;
}
.toast-info {
  background-color: #2F96B4;
}
.toast-warning {
  background-color: #F89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}


:root {
  --primary: #D8116B;
  --secondary: #316BFF;
  --white: #ffffff;
  --dark: #3B3E44;
  --bg-grey: #F6F7FB;
}


/* background color css */

.bg-grey {
  background-color: var(--bg-grey);
}

/* background color css end */

/* theme color */
.primary-color {
  color: var(--primary);
}

.secondary-color {
  color: var(--secondary)
}

.dark-color {
  color: var(--dark);
}

/* theme color css end */

/* border-radius */
.radius-10 {
  border-radius: 10px !important;
}

.radius-5 {
  border-radius: 5px !important;
}

/* border-radius end */

/* button css */
.button-primary {
  width: 100%;
  border-radius: 4px;
  /* padding: 11px 14px 11px 14px; */
  background-color: var(--primary);
  color: var(--white);
  border: none;
}

.button-primary1 {
  width: 80%;
  border-radius: 4px;
  /* padding: 11px 14px 11px 14px; */
  background-color: var(--primary);
  color: var(--white);
  border: none;
}

.button-secondary {
  width: 100%;
  border-radius: 4px;
  /* padding: 11px 14px 11px 14px; */
  background-color: var(--secondary);
  color: var(--white);
  border: none;
}

.button-dark {
  width: 100%;
  border-radius: 4px;
  /* padding: 11px 14px 11px 14px; */
  background-color: var(--dark);
  color: var(--white);
  border: none;
  height: 53px;
}

/* button css end */

.dropdown-toggle::after {
  display: none;
}

.round-trip {
  color: #3B3E44;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 500;
  word-wrap: break-word
}

/* text css */

.mid-font {
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  color: #484848;
  font-family: poppins;
}

.card {
  /* width: 1032px; */
  /* height: 93px; */
  left: 0px;
  top: 0px;
  /* position: absolute; */
  background: white;
  box-shadow: 0px 2px 4px rgba(72, 72, 72, 0.12);
  border-radius: 6px;
  border: 1px #E9EAF0 solid;
  height: auto !important;


}

/* form css */
.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #F79332 !important
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #D8116B !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field.ng-tns-c1205077789-0.mdc-text-field--filled:visited {
  border-radius: 10px;
}

.mat-mdc-text-field-wrapper.mdc-text-field.ng-tns-c1205077789-0.mdc-text-field--filled:hover {
  border-radius: 8px;
  text-decoration: none;
}

.mat-mdc-text-field-wrapper.mdc-text-field.ng-tns-c1205077789-1.mdc-text-field--filled:hover {
  border-radius: 8px;

}

.mat-mdc-text-field-wrapper.mdc-text-field.ng-tns-c1205077789-2.mdc-text-field--filled:hover {
  border-radius: 8px;

}

.mat-mdc-text-field-wrapper.mdc-text-field.ng-tns-c1205077789-3.mdc-text-field--filled:hover {
  border-radius: 8px !important;

}



.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border: none !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border: none !important;
}

/* form css end */






/* text css end */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-header .mat-step-icon {
  background-color: #D9D9D9 !important;
}

.mat-vertical-content {
  padding: 0px 24px 0px 24px !important;
}

@font-face {
  font-family: poppins;
  src:
    url("assets/fonts/Poppins-Regular.ttf") format("truetype"),
    url("assets/fonts/Poppins-ExtraLight.ttf"),
    url("assets/fonts/Poppins-Light.ttf")
}

.mat-step-header {
  margin: 0px;
  padding: 16px 19px 16px 0px !important;
}

.mat-vertical-content-container {
  margin-left: 10px !important;
  border: 0;
  position: relative;
}

.mat-vertical-stepper-header {
  height: 0px !important;
}

.continue-txt {
  font-size: 20px;
  font-weight: 700;
  font-family: Poppins;
  word-wrap: break-word;
  color: white;
}


.mat-mdc-tab-labels {
  display: flex;
  gap: 22px;
}

/* div#mat-tab-label-0-1 {
        background: white;
        border-radius: 8px;
    }


    div#mat-tab-label-0-0 {
        background: white;
        border-radius: 8px;
    }

    div#mat-tab-label-0-2 {
        background: white;
        border-radius: 8px;
    } */

.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

html {
  overflow-x: hidden;
}



.mat-mdc-tab.mat-mdc-tab-disabled {
  opacity: 1.4 !important;
  margin-left: 83% !important;
  position: fixed !important;
}



@media screen and (min-width: 1440) {
  .mat-mdc-tab.mat-mdc-tab-disabled {
    opacity: 1.4 !important;
    margin-left: 84% !important;
    position: fixed !important;
    /* Additional styles for larger screens */
  }
}

@media screen and (max-width: 1231px) {
  .mat-mdc-tab.mat-mdc-tab-disabled {
    opacity: 1.4 !important;
    margin-left: 75% !important;
    position: fixed !important;
  }
}

@media screen and (min-width: 1232px) and (max-width: 1511px) {
  .mat-mdc-tab.mat-mdc-tab-disabled {
    opacity: 1.4 !important;
    margin-left: 79% !important;
    position: fixed !important;
  }
}

@media screen and (min-width: 1512px) {
  .mat-mdc-tab.mat-mdc-tab-disabled {
    opacity: 1.4 !important;
    margin-left: 82% !important;
    /* Adjust margin for larger screens */
    position: fixed !important;
    /* Additional styles for larger screens */
  }
}




.common_card_tab {
  background-color: white;

}


.slider-value {
  color: #84878B;
  font-size: 18px;

  .mat-stepper-vertical-line::before {
    display: none;
  }

  font-family: Poppins;
  font-weight: 400;
  word-wrap: break-word
}

/* body.mat-typography {
  background-color: #FAFAFB;
} */





.hr {
  margin: 3px !important;
  color: #E6E8EC;
}

.mat-mdc-slider {
  width: 95% !important;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-right: 0;
  border-radius: 15px !important;

}

.mat-mdc-progress-spinner {
  color: #d8116b !important;
}
.custom-bg{
  background-color: #FAFAFB !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

